﻿.jdgm-widget.jdgm-widget, .jdgm-widget .jdgm-temp-hidden {
  display: block;
}

.jdgm-revs-tab__wrapper.jdgm-revs-tab__wrapper {
  display: none;
}

.jdgm-lock-scroll {
  overflow: hidden;
}

.jdgm-clearfix:before, .jdgm-clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.jdgm-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jdgm-line-clamp {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.jdgm-divider-top {
  border-top: 1px solid #eee;
  margin-top: 16px;
  padding-top: 16px;
}

.jdgm-preview-badge.jdgm--from-js .jdgm-prev-badge[data-average-rating="0.00"] {
  display: none !important;
}

.jdgm-rev__br:empty {
  display: block;
}

.jdgm-cf-bar:empty {
  display: inline-block;
}

.jdgm-paginate {
  width: 100%;
  text-align: center;
  padding-top: 16px;
}

.jdgm-paginate a:not([href]) {
  cursor: pointer;
}

.jdgm-paginate a:empty {
  display: inline-block;
}

.jdgm-paginate__page {
  cursor: pointer;
  vertical-align: middle;
  padding: 4px 8px;
  line-height: 1;
  display: inline-block;
}

.jdgm-paginate__page.jdgm-curt {
  pointer-events: none;
  font-size: 150%;
  font-weight: bold;
}

.jdgm-paginate__first-page, .jdgm-paginate__last-page, .jdgm-paginate__prev-page, .jdgm-paginate__next-page {
  padding: 4px;
  font-family: JudgemeIcons;
  font-size: 110%;
  font-weight: bold;
}

.jdgm-paginate__first-page:before {
  content: "";
}

.jdgm-paginate__last-page:before {
  content: "";
}

.jdgm-paginate__prev-page:before {
  content: "";
}

.jdgm-paginate__next-page:before {
  content: "";
}

.jdgm-paginate__load-more {
  margin-top: 16px;
}

@media (max-width: 767px) {
  .jdgm-paginate__page {
    min-width: 36px;
    margin: 2px 4px;
  }
}

.jdgm-all-reviews-widget, .jdgm-rev-widg {
  border: 1px solid #eee;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
}

.jdgm-rev-widg.jdgm-rev-widg {
  clear: both;
  display: block;
}

.jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-rev-widg__summary-stars, .jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-rev-widg__summary-text {
  display: inline-block;
}

.jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-rev-widg__summary-stars {
  margin-right: 8px;
}

.jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-rev-widg__sort-wrapper, .jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-paginate {
  display: none;
}

.jdgm-rev-widg__header:after {
  content: "";
  clear: both;
  display: block;
}

.jdgm-rev-widg__title {
  margin-bottom: 8px;
}

.jdgm-rev-widg__summary {
  float: left;
}

.jdgm-ask-question-btn, .jdgm-write-rev-link, .jdgm-paginate__load-more {
  text-align: center;
  border: 1px solid;
  padding: 5px 10px;
  display: inline-block;
}

.jdgm-widget-actions-wrapper ~ .jdgm-rev__br, .jdgm-rev-widg__sort-wrapper ~ .jdgm-rev__br, .jdgm-rev-widg__summary ~ .jdgm-rev__br {
  clear: both;
}

.jdgm-ask-question-btn {
  margin-left: 8px;
}

.jdgm-rev-widg__sort-wrapper {
  clear: both;
}

.jdgm-sort-dropdown-wrapper {
  display: inline-block;
  position: relative;
}

.jdgm-sort-dropdown.jdgm-sort-dropdown {
  -webkit-appearance: none;
  appearance: none;
  width: auto;
  text-indent: 0;
  max-width: 100%;
  z-index: 1;
  background: none;
  border: 1px solid #ddd;
  padding: 0 20px 0 8px;
  font-size: 12px;
  line-height: 2;
  display: block;
  position: relative;
}

.jdgm-sort-dropdown-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid #000;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  display: block;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.jdgm-rev-widg__paginate-spinner-wrapper {
  border-top: 1px solid #eee;
  margin-top: 16px;
  padding-top: 24px;
  display: none;
}

.jdgm-all-reviews-page__wrapper {
  max-width: 80vw;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.jdgm-all-reviews-page__wrapper .jdgm-branding-footer {
  text-align: center;
  color: initial;
  margin: 12px 0 -22px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  font-weight: 300;
  display: block;
}

.jdgm-all-reviews-widget > .jdgm-spinner {
  margin: 16px auto;
}

.jdgm-all-reviews__header:after {
  content: " ";
  clear: both;
  display: table;
}

.jdgm-all-reviews__summary {
  float: left;
}

.jdgm-all-reviews-page__load-more-wrapper {
  text-align: center;
}

.jdgm-all-reviews-page__load-more {
  text-align: center;
  cursor: pointer;
  border: 1px solid;
  padding: 5px 10px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .jdgm-all-reviews-page__wrapper {
    max-width: 90vw;
  }

  .jdgm-widget-actions-wrapper {
    width: 100%;
  }

  .jdgm-ask-question-btn, .jdgm-write-rev-link {
    width: 100%;
    margin-bottom: 16px;
    margin-left: 0;
    display: block;
  }
}

.ui-tabs.ui-widget .jdgm-rev-widg {
  border: none;
  margin-top: 0;
  padding: 0;
}

.jdgm-widget.jdgm-widget[data-auto-install="true"], .jdgm-carousel-wrapper[data-auto-install="true"] {
  display: none;
}

.jdgm-widget .jdgm-review-search.jdgm-review-search {
  margin-bottom: 12px;
  display: block;
}

.jdgm-widget-actions-wrapper {
  float: right;
  text-align: right;
}

.jdgm-widget .jdgm-review-search {
  width: 100%;
  min-height: 2em;
  border: 1px solid #ddd;
}

.jdgm-form-dynamic-wrapper {
  display: none;
}

.jdgm-histogram {
  float: left;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  margin: 0 24px;
  padding: 4px 16px;
  font-size: 12px;
}

.jdgm-histogram + .jdgm-rev__br {
  clear: both;
}

.jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-histogram {
  display: none;
}

.jdgm-histogram__row {
  height: 16px;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  line-height: 16px;
}

.jdgm-histogram__row[data-frequency="0"] {
  pointer-events: none;
}

.jdgm-histogram__row:last-of-type {
  margin-bottom: 0;
}

.jdgm-histogram__row:hover {
  opacity: .6;
}

.jdgm-histogram__row--selected .jdgm-histogram__bar:after {
  width: 100%;
}

.jdgm-histogram__clear-filter {
  margin-top: 8px;
  font-size: 14px;
  display: none;
}

.jdgm-histogram__star {
  vertical-align: middle;
  display: inline-block;
}

.jdgm-histogram__star .jdgm-star {
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
}

.jdgm-histogram__bar {
  width: 120px;
  height: 100%;
  vertical-align: middle;
  margin: 0 2px 0 4px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 1px #666;
}

.jdgm-histogram__bar:after {
  content: " ";
  height: 100%;
  width: 0;
  opacity: .3;
  background: #fbcd0a;
  transition: width .35s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.jdgm-histogram__bar-content {
  height: 100%;
  background: #fbcd0a;
  font-size: 9px;
  line-height: 16px;
  transition: width .3s;
  box-shadow: inset 0 0 1px #888;
}

.jdgm-histogram__percentage {
  width: 32px;
  vertical-align: middle;
  display: inline-block;
}

.jdgm-histogram__frequency {
  vertical-align: middle;
  margin-left: 3px;
  display: inline-block;
}

.jdgm-review-widget--medium .jdgm-histogram {
  width: 100%;
  border: none;
  margin: 16px 0 4px;
  padding: 0;
}

.jdgm-review-widget--medium .jdgm-histogram .jdgm-histogram__row {
  height: 18px;
  margin-bottom: 12px;
  line-height: 18px;
}

.jdgm-review-widget--medium .jdgm-histogram .jdgm-star {
  font-size: 16px;
  line-height: 18px;
}

.jdgm-review-widget--medium .jdgm-histogram .jdgm-histogram__bar-content {
  line-height: 18px;
}

.jdgm-review-widget--small .jdgm-histogram {
  width: 100%;
  border: none;
  margin: 16px 0 4px;
  padding: 0;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-histogram__row {
  height: 18px;
  margin-bottom: 12px;
  line-height: 18px;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-star {
  font-size: 16px;
  line-height: 18px;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-histogram__bar-content {
  line-height: 18px;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-histogram__bar {
  width: 80px;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-histogram__star .jdgm-star {
  padding-right: 2px !important;
}

.jdgm-review-widget--small .jdgm-histogram .jdgm-histogram__star .jdgm-star:last-of-type {
  padding-right: 0 !important;
}

@media only screen and (max-width: 768px) {
  .jdgm-histogram {
    width: 100%;
    border: none;
    margin: 16px 0 4px;
    padding: 0;
  }

  .jdgm-histogram .jdgm-histogram__row {
    height: 18px;
    margin-bottom: 12px;
    line-height: 18px;
  }

  .jdgm-histogram .jdgm-star {
    font-size: 16px;
    line-height: 18px;
  }

  .jdgm-histogram .jdgm-histogram__bar-content {
    line-height: 18px;
  }
}

@media only screen and (max-width: 320px) {
  .jdgm-histogram__bar {
    width: 80px;
  }

  .jdgm-histogram__star .jdgm-star {
    padding-right: 2px !important;
  }

  .jdgm-histogram__star .jdgm-star:last-of-type {
    padding-right: 0 !important;
  }
}

.jdgm-question-form-wrapper, .jdgm-form-wrapper {
  border-top: 1px solid #eee;
  margin-top: 16px;
}

.jdgm-widget label {
  width: 100%;
  margin: 20px 0 5px;
  line-height: 1;
  display: block;
}

.jdgm-widget div.jdgm-input-error {
  width: 100%;
  background: #ff0;
  margin: 0;
}

.jdgm-widget .jdgm-form__inline-label {
  width: auto;
  margin-right: 3px;
  display: inline-block;
}

.jdgm-widget input:not([type="submit"]) {
  width: 100%;
  min-height: 2em;
  border: 1px solid #ddd;
  margin: 0;
}

.jdgm-widget textarea {
  width: 100%;
  border: 1px solid #ddd;
}

.jdgm-widget .jdgm-custom-forms .jdgm-spinner {
  margin-top: 16px;
}

.jdgm-widget .jdgm-cf__quest-label {
  margin-top: 15px;
}

.jdgm-widget .jdgm-cf__required-sym {
  color: red;
  vertical-align: middle;
  font-size: 120%;
}

.jdgm-widget label.jdgm-cf__option {
  margin: 0;
  padding: 6px 0;
}

.jdgm-widget input.jdgm-cf-input:not([type="text"]) {
  width: auto;
  vertical-align: middle;
  margin-right: 8px;
}

.jdgm-widget .jdgm-submit-question, .jdgm-widget .jdgm-submit-rev {
  margin-top: 11px;
}

.jdgm-form__title-fieldset > label, .jdgm-form__body-fieldset > label, .jdgm-form__name-fieldset > label {
  width: auto;
  margin-right: 3px;
  display: inline-block;
}

.jdgm-form__reviewer-name-format-dropdown-wrapper {
  position: relative;
}

.jdgm-form__reviewer-name-format-dropdown {
  -webkit-appearance: none;
  appearance: none;
  color: #333;
  width: auto;
  max-width: auto;
  z-index: 1;
  background: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 4px;
  padding: 0 20px 0 0;
  display: inline-block;
  position: relative;
}

.jdgm-form__reviewer-name-format-dropdown-arrow {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 6px solid #000;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  margin-left: -16px;
  display: inline-block;
}

.jdgm-countdown {
  font-size: .75rem;
}

.jdgm-notification {
  color: green;
  border-top: 1px solid #eee;
  margin-top: 16px;
  padding-top: 16px;
  font-weight: bold;
}

.jdgm-form-yt-video {
  max-width: 400px;
  margin-top: 8px;
}

.jdgm-form-yt-video__iframe-wrapper {
  width: 100%;
  padding-bottom: 55%;
  display: inline-block;
  position: relative;
}

.jdgm-form-yt-video__iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.jdgm-form-dynamic-wrapper.jdgm-form-dynamic-wrapper {
  display: none;
}

.jdgm-form-dynamic-wrapper.jdgm-show {
  height: 100vh;
  visibility: visible;
  display: block;
}

.jdgm-form-dynamic-wrapper {
  z-index: 99999999;
  position: fixed;
  inset: 0;
}

.jdgm-form-dynamic {
  width: 100%;
  max-width: 500px;
  height: auto;
  max-height: 655px;
  text-align: center;
  background: #fff;
  padding: 32px;
  position: absolute;
  top: 45%;
  left: 50%;
  overflow: auto;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-form-dynamic .jdgm-form-dynamic__next {
  float: right;
}

.jdgm-form-dynamic .jdgm-form-dynamic__back {
  float: left;
}

.jdgm-form-dynamic .jdgm-form-dynamic__submit-rev {
  float: right;
  margin-top: 0;
  display: none;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row {
  width: auto;
  text-align: left;
  min-width: 65%;
  padding-top: 32px;
  font-size: 16px;
  display: none;
  overflow-x: auto;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row label {
  font-size: 18px;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row .jdgm-cf__option {
  font-size: 16px;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__picture-upload-field {
  width: 100%;
  text-align: center;
  padding-top: 0;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__picture-upload-field label {
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: bold;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__picture-upload-field .jdgm-picture-fieldset__box {
  width: 24%;
  margin: 0 8px 0 0;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__rating-fieldset {
  text-align: center;
  padding-top: 48px;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__rating-fieldset label {
  margin: 24px 0;
  font-size: 24px;
  font-weight: bold;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__rating-fieldset .jdgm-form-dynamic__rating {
  font-size: 40px;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-form-dynamic__submit-slide {
  width: 90%;
}

.jdgm-form-dynamic .jdgm-form-dynamic__row.jdgm-active, .jdgm-form-dynamic .jdgm-form-dynamic__buttons-row.jdgm-active {
  display: inline-block;
}

.jdgm-form-dynamic .jdgm-form-yt-video {
  max-width: unset;
  margin-bottom: 50px;
}

.jdgm-form-dynamic .jdgm-form__yt-video-fieldset {
  margin-bottom: 16px;
}

.jdgm-form-dynamic__buttons-row {
  display: none;
}

.jdgm-form-dynamic__row.jdgm-active .jdgm-form-dynamic__submit-rev, .jdgm-form-dynamic__row.jdgm-active .jdgm-form-dynamic__buttons-row {
  display: inline-block;
}

.jdgm-form-dynamic__title-fieldset > label, .jdgm-form-dynamic__body-fieldset > label {
  width: auto;
  margin-right: 3px;
  display: inline-block;
}

@media (max-width: 480px) {
  .jdgm-form-dynamic {
    max-height: 100%;
  }

  .jdgm-form-dynamic .jdgm-form-dynamic__row {
    width: 90%;
  }

  .jdgm-form-dynamic .jdgm-form-dynamic__row .jdgm-cf__option {
    font-size: 14px;
  }

  .jdgm-widget .jdgm-form-dynamic .jdgm-picture-fieldset__box {
    min-width: 70px;
  }

  .jdgm-form-dynamic__buttons-row {
    left: 10px;
    right: 10px;
  }
}

@media (max-width: 320px) {
  .jdgm-form-dynamic .jdgm-form-dynamic__row {
    width: 100%;
  }

  .jdgm-form-dynamic .jdgm-form-dynamic__buttons-row {
    left: 4px;
    right: 4px;
  }
}

.jdgm-preview-badge--with-link {
  cursor: pointer;
}

.enforce-center-preview-badge:after {
  clear: both;
  content: " ";
  display: table;
}

.enforce-center-preview-badge .jdgm-prev-badge__stars {
  float: left;
  text-align: right;
  width: 49%;
  width: calc(50% - 3px);
}

.enforce-center-preview-badge .jdgm-prev-badge__text {
  float: right;
  text-align: left;
  width: 49%;
  width: calc(50% - 3px);
}

.jdgm-prev-badge.jdgm--waiting-for-batch-done {
  display: none !important;
}

.jdgm-qa-badge {
  border-left: 1px solid;
  margin-left: 8px;
  padding-left: 8px;
}

.jdgm-qa-badge.jdgm-qa-badge__pos-below {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
  display: block;
}

.jdgm-qa-badge__icon {
  vertical-align: middle;
  padding-right: 4px;
  font-family: JudgemeIcons;
  display: inline-block;
}

.jdgm-qa-badge__icon:before {
  content: "";
}

.jdgm-qa-badge__text {
  vertical-align: middle;
  display: inline-block;
}

.jdgm-quest, .jdgm-rev {
  border-top: 1px solid #eee;
  margin-top: 16px;
  padding-top: 16px;
  position: relative;
  overflow: hidden;
}

.jdgm-quest .jdgm-rev__icon:after, .jdgm-rev .jdgm-rev__icon:after {
  display: none;
}

.jdgm-quest[data-verified-buyer="true"] .jdgm-rev__icon:not(.jdgm--loading):after, .jdgm-rev[data-verified-buyer="true"] .jdgm-rev__icon:not(.jdgm--loading):after {
  width: 16px;
  height: 16px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  font-family: JudgemeIcons;
  font-size: 9px;
  font-weight: bold;
  line-height: 15px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.jdgm-rev__header {
  margin-bottom: 5px;
}

.jdgm-rev__header:after {
  content: " ";
  clear: both;
  display: block;
}

.jdgm-rev__avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.jdgm-rev__icon {
  float: left;
  width: 3.2em;
  height: 3.2em;
  text-align: center;
  color: #333;
  background-color: #e9e9e9;
  border-radius: 50%;
  margin-right: 12px;
  line-height: 3.2em;
  position: relative;
}

.jdgm-rev__icon.jdgm--loading {
  visibility: hidden;
}

.jdgm-rev__icon.jdgm--loading:after {
  width: 40px;
  height: 40px;
  content: "";
  width: 90%;
  height: 90%;
  visibility: visible;
  background-color: #fff;
  border: 2px solid #eee;
  border-left-color: #ccc;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
  position: absolute;
  top: 0;
}

.jdgm-rev__author-wrapper {
  vertical-align: middle;
  font-weight: bold;
}

.jdgm-rev__author-wrapper .jdgm-rev__location-country-flag-img {
  height: 1em;
  max-width: 1.5em;
  vertical-align: middle;
  border: 1px solid #eee;
  display: inline-block;
}

.jdgm-rev__author-wrapper .jdgm-rev__location-country-flag-img.jdgm--loading {
  visibility: hidden;
  position: relative;
}

.jdgm-rev__author-wrapper .jdgm-rev__location-country-flag-img.jdgm--loading:after {
  width: 40px;
  height: 40px;
  content: "";
  width: 1em;
  height: 1em;
  visibility: visible;
  border: 2px solid #eee;
  border-left-color: #ccc;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
  position: absolute;
  top: 0;
}

.jdgm-rev__author {
  vertical-align: middle;
}

.jdgm-rev__timestamp {
  margin-left: 4px;
}

.jdgm-rev__timestamp + .jdgm-rev__br {
  padding-bottom: 1px;
}

.jdgm-rev__timestamp.jdgm-spinner {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  color: #0000;
  border-width: 2px;
  border-left-color: #aaa;
  font-size: 0;
  display: inline-block;
}

.jdgm-rev__title {
  font-size: 110%;
  display: block;
}

.jdgm-rev__location {
  opacity: .35;
  vertical-align: middle;
}

.jdgm-rev__body > p {
  margin-bottom: 16px;
}

.jdgm-rev__body > p:last-of-type {
  margin-bottom: 0;
}

.jdgm-rev__body-read-more {
  cursor: pointer;
  display: none;
}

.jdgm-ans__body.is-truncated .jdgm-rev__body-read-more, .jdgm-rev__body.is-truncated .jdgm-rev__body-read-more {
  display: inline;
}

.jdgm-rev__cf-ans {
  margin-top: 8px;
}

.jdgm-rev__cf-ans:last-of-type {
  margin-bottom: 8px;
}

.jdgm-rev__cf-ans__title {
  font-size: 90%;
  display: block;
}

.jdgm-cf-bars-wrapper {
  margin-right: 4px;
  font-size: 0;
  display: inline-block;
}

.jdgm-cf-bar {
  width: 25px;
  height: 8px;
  opacity: .1;
  background: currentColor;
  margin: 2px 2px 0 0;
  display: inline-block;
}

.jdgm-cf-bar.jdgm--filled {
  opacity: 1;
}

.jdgm-rev__reply {
  background: #f2f2f2;
  border-left: 3px solid #ddd;
  padding: 0 16px;
}

.jdgm-rev__replier-wrapper {
  margin: 16px 0 0;
  padding-top: 8px;
}

.jdgm-rev__reply-content {
  margin: 0;
  padding-bottom: 8px;
}

.jdgm-rev__actions:after {
  content: "";
  clear: both;
  display: block;
}

.jdgm-rev__social {
  float: left;
}

.jdgm-rev__votes {
  float: right;
}

.jdgm-rev__social-inner, .jdgm-rev__votes-inner {
  margin-top: 4px;
  margin-bottom: -4px;
}

.jdgm-rev__thumb-count {
  margin-right: 12px;
}

.jdgm-rev__thumb-count:last-of-type {
  margin-right: 0;
}

.jdgm-rev__share-btn, .jdgm-rev__thumb-btn {
  text-align: center;
  color: #666;
  cursor: pointer;
  margin-right: 4px;
  padding: 4px 8px;
  font-family: JudgemeIcons;
  font-size: 16px;
  display: inline-block;
}

.jdgm-rev__share-btn:hover, .jdgm-rev__thumb-btn:hover {
  opacity: 1;
}

.jdgm-rev__share-btn:first-of-type, .jdgm-rev__thumb-btn:first-of-type {
  margin-left: -8px;
}

.jdgm-rev__thumb-btn:hover {
  color: #000;
}

.jdgm-rev__share-fb:before {
  content: "";
}

.jdgm-rev__share-fb:hover {
  color: #3b5998;
}

.jdgm-rev__share-twitter:before {
  content: "";
}

.jdgm-rev__share-twitter:hover {
  color: #1da1f2;
}

.jdgm-rev__share-google:before {
  content: "";
}

.jdgm-rev__share-google:hover {
  color: #dd4b39;
}

.jdgm-rev__share-pinterest:before {
  content: "";
}

.jdgm-rev__share-pinterest:hover {
  color: #e60023;
}

.jdgm-rev__share-linkedin:before {
  content: "";
}

.jdgm-rev__share-linkedin:hover {
  color: #0073b1;
}

.jdgm-rev_thumb-up:before {
  content: "";
}

.jdgm-rev_thumb-down:before {
  content: "";
}

.jdgm-rev__buyer-badge {
  vertical-align: middle;
  padding: 3px 7px;
  font-size: 11px;
  line-height: 1;
  display: none;
}

.jdgm-buyer-badge--top-right .jdgm-rev__timestamp ~ .jdgm-rev__br, .jdgm-buyer-badge--right-of-timestamp .jdgm-rev__timestamp ~ .jdgm-rev__br {
  display: inline;
}

.jdgm-buyer-badge--top-right .jdgm-rev__buyer-badge-wrapper:after, .jdgm-buyer-badge--right-of-timestamp .jdgm-rev__buyer-badge-wrapper:after {
  content: "";
  display: block;
}

.jdgm-buyer-badge--top-right .jdgm-rev__buyer-badge {
  float: right;
  font-size: inherit;
}

.jdgm-buyer-badge--removed .jdgm-rev__buyer-badge {
  visibility: hidden !important;
  display: none !important;
}

.jdgm-buyer-badge--right-of-timestamp .jdgm-rev__buyer-badge-wrapper {
  margin-left: 4px;
}

.jdgm-buyer-badge--text-only .jdgm-rev__buyer-badge {
  font-size: inherit;
  vertical-align: inherit;
  padding: 0;
  display: inline;
}

.jdgm-rev_all-rev-page-picture-separator {
  height: 120px;
  border-left: 2px solid #ddd;
  margin: 0 20px;
  display: inline-block;
}

.jdgm-widget .jdgm-picture-fieldset-title {
  margin: 20px 0 5px;
  line-height: 1;
}

.jdgm-widget .jdgm-picture-fieldset__input {
  width: 100%;
  opacity: 0;
  visibility: visible;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.jdgm-widget .jdgm-picture-fieldset__box {
  cursor: pointer;
  width: 30%;
  max-width: 150px;
  min-width: 90px;
  margin: 0 5px 0 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.jdgm-picture-fieldset {
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  overflow: auto;
}

.jdgm-picture-fieldset__box-wrapper {
  width: 100%;
  height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #eee;
  padding-bottom: calc(100% - 2px);
  position: relative;
}

.jdgm-picture-fieldset__box-wrapper.jdgm-has-preview-image--videos {
  background-image: url("https://s3.amazonaws.com/me.judge.public-static-assets/general/video-placeholder-640x480.jpeg");
}

.jdgm-picture-fieldset__icon {
  color: #bbb;
  font-family: JudgemeIcons;
  font-size: 72px;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-photocamera-icon:before {
  content: "";
}

.jdgm-videocam-icon:before {
  content: "";
}

.jdgm-picture-fieldset__spinner-wrapper {
  width: 50%;
  height: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-picture-fieldset__box:hover {
  opacity: .7;
}

.jdgm-picture-fieldset__box .jdgm-spinner {
  width: 100%;
  height: 100%;
}

.jdgm-picture-fieldset__delete {
  text-align: center;
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  margin: auto 0;
  padding: 2px 4px 1px 5px;
  font-family: JudgemeIcons;
  position: absolute;
  top: 4px;
  right: 4px;
  overflow: hidden;
  box-shadow: 0 2px 2px #0003;
}

.jdgm-picture-fieldset__delete:before {
  content: "";
}

.jdgm-is-uploading-picture .jdgm-picture-fieldset__spinner-wrapper {
  display: block;
}

.jdgm-picture-fieldset__progress-text {
  color: #bbb;
  font-size: 14px;
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jm-mfp-counter .jdgm-branding-footer {
  color: #fff;
}

.jm-mfp-counter .jdgm-branding-footer:hover, .jm-mfp-counter .jdgm-branding-footer:active {
  color: #fff;
  opacity: .8;
}

.jdgm-rev__pics {
  white-space: nowrap;
  height: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  overflow: auto;
}

.jdgm-rev__pic-link {
  height: 120px;
  width: auto;
  cursor: pointer;
  margin: 8px 5px 3px 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.jdgm-rev__pic-link:focus {
  outline: none;
}

.jdgm-rev__pic-link:hover {
  opacity: .7;
}

.jdgm-rev__pic-link:last-of-type {
  margin-right: 0;
}

.jdgm-rev__pic-link.jdgm--loading {
  width: 120px;
  padding: 20px;
}

.jdgm-rev__pic-link.jdgm--loading .jdgm-rev__pic-img {
  visibility: hidden;
  height: 1px;
}

.jdgm-rev__pic-link.jdgm--loading:after {
  width: 40px;
  height: 40px;
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #eee;
  border-left-color: #ccc;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
}

.jdgm-rev__pic-img {
  width: auto;
  max-height: 100%;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.jdgm-rev__body img {
  max-height: 120px;
  border-radius: 4px;
  margin: 8px 5px 3px 0;
}

.jdgm-rev__vids {
  white-space: nowrap;
  overflow: auto;
}

.jdgm-vid-player {
  height: 240px;
  vertical-align: middle;
  margin-top: 16px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
}

.jdgm-vid-player:last-of-type {
  margin-right: 0;
}

.jdgm-vid-player__wrapper {
  font-size: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.jdgm-vid-player__wrapper--with-placeholder {
  height: 100%;
}

.jdgm-vid-player__wrapper--with-placeholder:hover {
  opacity: .7;
}

.jdgm-vid-player__wrapper.jdgm--loading {
  width: 160px;
  height: 160px;
  padding: 40px;
}

.jdgm-vid-player__wrapper.jdgm--loading:after {
  width: 40px;
  height: 40px;
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #eee;
  border-left-color: #ccc;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
}

.jdgm-vid-player__placeholder {
  height: 100%;
  text-decoration: none;
  display: block;
}

.jdgm-vid-player__placeholder > img {
  height: 100%;
  width: auto;
  display: block;
}

.jm-mfp-is-open.jm-mfp-is-open {
  overflow: hidden;
}

.jm-mfp-container button.jm-mfp-close, .jm-mfp-container button.jm-mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  opacity: .65;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

.jm-mfp-container button.jm-mfp-close:hover, .jm-mfp-container button.jm-mfp-close:focus, .jm-mfp-container button.jm-mfp-arrow:hover, .jm-mfp-container button.jm-mfp-arrow:focus {
  opacity: 1;
}

.jm-mfp-container img.jm-mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 48px 0 32px;
  line-height: 0;
  transition: transform .5s;
  display: block;
}

.jm-mfp-image-holder .mfp-close {
  padding-right: 0;
  right: 0;
}

.jm-mfp-preloader {
  width: 100px;
  height: 100px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jm-mfp-preloader > a {
  color: #fff;
}

.jm-mfp-preloader:before {
  width: 40px;
  height: 40px;
  content: " ";
  width: 100%;
  height: 100%;
  border: 2px solid #555;
  border-left-color: #fff;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
}

.jm-mfp-bg {
  width: 100%;
  height: 100%;
  z-index: 1042;
  background: #000000e6;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.jm-mfp-bg:empty {
  display: block;
}

.jm-mfp-wrap {
  width: 100%;
  height: 100%;
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  outline: none !important;
}

.jm-mfp-container {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.jm-mfp-container:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.jm-mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.jm-mfp-inline-holder .jm-mfp-content, .jm-mfp-ajax-holder .jm-mfp-content {
  width: 100%;
  cursor: auto;
}

.jm-mfp-close, .jm-mfp-arrow, .jm-mfp-preloader, .jm-mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.jm-mfp-loading.jm-mfp-figure {
  display: none;
}

.jm-mfp-hide {
  display: none !important;
}

.jm-mfp-s-ready .jm-mfp-preloader, .jm-mfp-s-error .jm-mfp-content {
  display: none;
}

.jm-mfp-close {
  width: 48px;
  height: 48px;
  color: #fff;
  text-align: right;
  font-family: Arial, Baskerville, monospace;
  font-size: 48px;
  font-style: normal;
  line-height: 48px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.jm-mfp-close:active {
  top: 1px;
}

.jm-mfp-counter {
  color: #fff;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  line-height: 2;
  position: absolute;
  top: 0;
  right: 0;
}

.jm-mfp-counter__number {
  float: right;
}

.jm-mfp-arrow {
  height: 48px;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  margin: 0;
  font-family: JudgemeIcons;
  font-size: 48px;
  position: absolute;
  top: calc(50% - 24px);
}

.jm-mfp-arrow-left {
  left: 24px;
}

.jm-mfp-arrow-left:before {
  content: "";
}

.jm-mfp-arrow-right {
  right: 24px;
}

.jm-mfp-arrow-right:before {
  content: "";
}

.jm-mfp-figure {
  line-height: 0;
}

.jm-mfp-figure:after {
  content: "";
  width: auto;
  height: auto;
  z-index: -1;
  background: #000;
  display: block;
  position: absolute;
  inset: 48px 0 32px;
}

.jm-mfp-figure figure {
  margin: 0;
  padding: 0;
}

.jm-mfp-bottom-bar {
  width: 100%;
  cursor: auto;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.jm-mfp-image-holder .jm-mfp-content {
  max-width: 100%;
}

.jm-mfp-gallery .jm-mfp-image-holder .jm-mfp-figure {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .jm-mfp-img-mobile .jm-mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .jm-mfp-img-mobile img.jm-mfp-img {
    padding: 0;
  }

  .jm-mfp-img-mobile .jm-mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .jm-mfp-img-mobile .jm-mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .jm-mfp-img-mobile .jm-mfp-bottom-bar {
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .jm-mfp-img-mobile .jm-mfp-bottom-bar:empty {
    padding: 0;
  }

  .jm-mfp-img-mobile .jm-mfp-counter {
    top: 3px;
    right: 5px;
  }

  .jm-mfp-img-mobile .jm-mfp-close {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0009;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

.jdgm-carousel-wrapper {
  width: 80%;
  margin: auto;
  padding: 48px 0;
}

.jdgm-carousel-wrapper .jdgm-all-reviews-rating-wrapper {
  text-align: center;
  display: block;
}

.jdgm-carousel-wrapper[data-theme="card"] .jdgm-verified-by-judgeme {
  text-align: left;
}

.jdgm-carousel-title-and-link {
  margin-bottom: 24px;
}

.jdgm-carousel-title {
  text-align: center;
  margin: 0;
}

.jdgm-carousel {
  width: 100%;
  margin: 25px auto;
}

.jdgm-carousel__item-container {
  overflow: auto;
}

.jdgm-carousel__item-wrapper {
  width: 100%;
  height: 200px;
  white-space: nowrap;
}

.jdgm-carousel-item {
  height: 100%;
  width: 33.3%;
  vertical-align: middle;
  padding: 0 24px;
  display: inline-block;
  overflow: hidden;
}

.jdgm-carousel-item.jdgm--shop-review .jdgm-carousel-item__product {
  display: none;
}

.jdgm-carousel-item.jdgm--shop-review .jdgm-carousel-item__product.jdgm--shop-review-has-image {
  display: block;
}

@media only screen and (min-width: 768px) {
  .jdgm-carousel-wrapper[data-theme="card"] .jdgm-carousel-title-and-link {
    position: relative;
  }

  .jdgm-carousel-wrapper[data-theme="card"] .jdgm-verified-by-judgeme {
    position: absolute;
    bottom: 0;
    right: 20px;
  }

  .jdgm-carousel-wrapper[data-theme="vertical"] {
    position: relative;
  }

  .jdgm-carousel-wrapper[data-theme="vertical"] .jdgm-verified-by-judgeme {
    position: absolute;
    top: 64px;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jdgm-carousel-item {
    width: 50%;
  }

  .jdgm-carousel-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .jdgm-carousel-item, .jdgm-carousel-wrapper {
    width: 100%;
  }
}

.jdgm-carousel-item__review-rating {
  font-size: 120%;
}

.jdgm-carousel-item__review {
  width: 100%;
  height: calc(72% - 4em);
  text-align: center;
  white-space: normal;
  overflow: hidden;
}

.jdgm-carousel-item__review-title {
  font-weight: bold;
}

.jdgm-carousel-item__review-title a {
  word-break: break-word;
}

.jdgm-carousel-item__review-body, .jdgm-carousel-item__review-body > p {
  margin: 0;
}

.jdgm-carousel-item__review-body a {
  word-break: break-word;
}

.jdgm-carousel-item__reviewer-name-wrapper {
  text-align: center;
  margin: 6px 0;
}

.jdgm-carousel-item__reviewer-name {
  opacity: .6;
  padding-bottom: 2px;
  font-size: 90%;
  line-height: 1;
}

.jdgm-carousel-item__timestamp {
  opacity: .35;
  font-size: 75%;
  font-style: italic;
  line-height: 1;
}

.jdgm-carousel-item__product {
  text-align: center;
  height: 28%;
  width: 100%;
  display: block;
}

.jdgm-carousel-item__product-image {
  height: 100%;
  width: auto;
  margin: 0 auto;
  display: block;
}

.jdgm-carousel-item__product-image[src=""] {
  display: none;
}

.jdgm-carousel-item__product-image[src=""] + .jdgm-carousel-item__product-title {
  padding-top: 16px;
  font-size: 120%;
}

.jdgm-carousel-item__product-title {
  width: 100%;
  font-size: 90%;
}

.jdgm-carousel__arrows {
  text-align: center;
  justify-content: center;
  margin: 20px auto 10px;
  font-size: 0;
  display: flex;
}

.jdgm-carousel--vertical-theme .jdgm-carousel__arrows {
  display: block;
}

.jdgm-carousel[data-arrows-on-sides="true"]:not(.jdgm-carousel--vertical-theme) .jdgm-carousel__arrows {
  margin: 0;
  display: block;
}

.jdgm-carousel__right-arrow, .jdgm-carousel__left-arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 4px #eee;
  display: inline-block;
  transform: rotate(-45deg);
}

.jdgm-carousel__right-arrow:empty, .jdgm-carousel__left-arrow:empty {
  display: inline-block;
}

.jdgm-carousel__right-arrow:hover, .jdgm-carousel__left-arrow:hover {
  opacity: .6;
}

.jdgm-carousel__right-arrow {
  border-style: none solid solid none;
}

.jdgm-carousel__left-arrow {
  border-style: solid none none solid;
  margin-right: 10px;
}

.jdgm-carousel--done .jdgm-carousel__item-container {
  position: relative;
  overflow: hidden;
}

.jdgm-carousel--done .jdgm-carousel__item-wrapper {
  transition: transform .5s;
  position: relative;
}

.jdgm-carousel-item__timestamp.jdgm-ellipsis.jdgm-spinner {
  color: #0000;
}

.jdgm-revs-tab {
  z-index: 1041;
  position: relative;
}

.jdgm-revs-tab.jdgm-revs-tab {
  display: none;
}

.jdgm-revs-tab .jdgm-branding-footer {
  color: #fff;
}

.jdgm-revs-tab .jdgm-histogram {
  border: 0;
  margin-left: 0;
  padding-left: 0;
}

.jdgm-revs-tab-btn {
  width: auto;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0;
  display: inline-block;
  position: fixed;
}

.jdgm-revs-tab-btn[position="bottom"] {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.jdgm-revs-tab-btn[position="left"] {
  transform-origin: 0;
  top: 50%;
  left: 0;
  transform: rotate(-90deg)translate(-50%, 50%);
}

.jdgm-revs-tab-btn[position="right"] {
  transform-origin: 100%;
  top: 50%;
  right: 0;
  transform: rotate(90deg)translate(50%, 50%);
}

.jdgm-revs-tab-btn[position="none"] {
  display: none;
}

.jdgm-revs-tab__header {
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 8px 24px;
  position: absolute;
  bottom: 100%;
}

.jdgm-revs-tab__title {
  text-align: center;
  margin-bottom: 0;
}

.jdgm-mask {
  width: 100%;
  height: 100%;
  opacity: .75;
  background-color: #000;
}

.jdgm-mask:empty {
  display: block;
}

.jdgm-revs-tab__wrapper {
  z-index: 99999999;
  position: fixed;
  inset: 0;
}

.jdgm-revs-tab__wrapper.jdgm-revs-tab__wrapper {
  display: none;
}

.jdgm-revs-tab__wrapper.jdgm-show {
  display: block;
}

.jdgm-revs-tab__main {
  height: 70%;
  width: 85%;
  max-width: 600px;
  background: #fff;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.jdgm-revs-tab__content-header:after {
  content: " ";
  clear: both;
  display: table;
}

.jdgm-revs-tab__content {
  height: 100%;
  padding: 16px 24px;
  overflow: auto;
  box-shadow: inset 0 5px 9px -8px #000;
}

.jdgm-revs-tab__spinner {
  margin-top: 16px;
}

.jdgm-revs-tab__branding {
  text-align: center;
  line-height: 2;
}

.jdgm-close-ico {
  width: 32px;
  height: 32px;
  opacity: .5;
  position: absolute;
  top: 12px;
  right: 12px;
}

.jdgm-close-ico:empty {
  display: block;
}

.jdgm-close-ico:hover {
  opacity: 1;
}

.jdgm-close-ico:before {
  transform: rotate(45deg);
}

.jdgm-close-ico:after {
  transform: rotate(-45deg);
}

.jdgm-close-ico:before, .jdgm-close-ico:after {
  content: " ";
  height: 100%;
  width: 2px;
  background-color: #333;
  position: absolute;
  left: 15px;
}

@media only screen and (max-width: 375px) {
  .jdgm-revs-tab__main {
    width: 95%;
  }

  .jdgm-close-ico {
    width: 28px;
    height: 28px;
    top: 4px;
    right: 4px;
  }
}

.jdgm-subtab {
  border-bottom: 1px solid #eee;
  padding-top: 12px;
}

.jdgm-subtab ~ .jdgm-rev-widg__body .jdgm-rev:first-of-type, .jdgm-subtab ~ .jdgm-all-reviews__body .jdgm-rev:first-of-type, .jdgm-subtab ~ .jdgm-shop-reviews__body .jdgm-rev:first-of-type, .jdgm-subtab ~ .jdgm-revs-tab__content-body .jdgm-rev:first-of-type, .jdgm-subtab ~ .jdgm-quest-widg__body .jdgm-quest:first-of-type {
  border-top: 0;
  margin-top: 0;
}

.jdgm-subtab ~ .jdgm-rev-widg__paginate-spinner-wrapper {
  margin-top: 0;
}

.jdgm-subtab__name {
  cursor: pointer;
  margin-right: 16px;
  padding: 8px 0;
  line-height: 1;
  display: inline-block;
}

.jdgm-subtab__name.jdgm--active {
  border-bottom: 3px solid;
}

.jdgm-subtab__name:hover {
  opacity: .8;
}

.jdgm-quest .jdgm-rev__timestamp {
  margin-left: 0;
}

.jdgm-quest .jdgm-rev__buyer-badge-wrapper {
  display: none;
}

.jdgm-ans {
  background: #f2f2f2;
  border-left: 3px solid #ddd;
  margin-top: 16px;
  padding: 8px 16px;
}

.jdgm-ans .jdgm-rev__timestamp {
  font-size: 95%;
}

.jdgm-quest__body:before, .jdgm-ans__body:before {
  float: left;
  padding-right: 4px;
}

.jdgm-verified-badge {
  width: 150px;
  margin: 16px;
  font-family: Helvetica Neue;
}

.jdgm-verified-badge.jdgm-verified-badge.jdgm-verified-badge {
  display: inline-block;
}

.jdgm-verified-badge .jdgm-verified-badge__wrapper {
  position: relative;
}

.jdgm-verified-badge img {
  width: 100%;
  display: block;
}

.jdgm-verified-badge .jdgm-verified-badge__stars {
  width: 100%;
  text-align: center;
  line-height: 11px;
  position: absolute;
  top: 35%;
}

.jdgm-verified-badge .jdgm-verified-badge__stars.jdgm--last-level {
  top: 38.5%;
}

.jdgm-verified-badge .jdgm-verified-badge__stars .jdgm-star {
  font-size: 11px;
  color: #fffb00 !important;
  padding-right: 3px !important;
}

.jdgm-verified-badge .jdgm-verified-badge__stars .jdgm-star:last-of-type {
  padding-right: 0 !important;
}

.jdgm-verified-badge .jdgm-badge-text {
  width: 100%;
  color: #fff;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  position: absolute;
}

.jdgm-verified-badge .jdgm-verified-badge__total {
  font-size: 21px;
  top: 19%;
}

.jdgm-verified-badge .jdgm-verified-badge__total.jdgm--last-level {
  font-size: 20px;
  top: 24%;
}

.jdgm-verified-badge .jdgm-verified-badge__text {
  font-size: 13px;
  top: 51%;
}

.jdgm-verified-badge .jdgm-verified-badge__text.jdgm--last-level {
  top: 54%;
}

.jdgm-verified-wrapper {
  display: inline-block;
}

.jdgm-verified-by {
  justify-content: left;
  display: flex;
}

.jdgm-verified-by__text, .jdgm-verified-by__image {
  height: 24px;
  display: inline-block;
}

.jdgm-verified-by__text {
  margin-right: 8px;
  font-size: 14px;
  line-height: 24px;
}

.jdgm-verified-by__image img, .jdgm-verified-by__image svg {
  height: 100%;
}

.jdgm-yt-video {
  display: inline-block;
  position: relative;
}

.jdgm-yt-video > img {
  object-fit: cover;
}

.jdgm-yt-video__play-btn {
  width: 90px;
  height: 60px;
  opacity: .8;
  cursor: pointer;
  background-color: #333;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-yt-video__play-btn:before {
  content: "";
  border: 15px solid #0000;
  border-left: 26px solid #fff;
  border-right-width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-rev-widg[data-number-of-reviews="0"] .jdgm-gallery {
  display: none;
}

.jdgm-gallery {
  width: calc(100% + 8px);
  margin: 0 -4px 8px;
  display: inline-block;
  overflow: hidden;
}

.jdgm-gallery .jdgm-gallery__thumbnail-link {
  width: 25%;
}

.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
  display: inline-block;
}

.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
  content: none;
}

.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(4) .jdgm-gallery__thumbnail-wrapper:before {
  content: "";
}

.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+5) {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jdgm-gallery .jdgm-gallery__thumbnail-link {
    width: 12.5%;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
    display: inline-block;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
    content: none;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(8) .jdgm-gallery__thumbnail-wrapper:before {
    content: "";
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+9) {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .jdgm-gallery {
    width: 216px;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link {
    width: 64px;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
    display: inline-block;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
    content: none;
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(6) .jdgm-gallery__thumbnail-wrapper:before {
    content: "";
  }

  .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+7) {
    display: none;
  }
}

.jdgm-gallery__thumbnail-link {
  float: left;
  position: relative;
}

.jdgm-gallery__thumbnail-link:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.jdgm-gallery__thumbnail-link:hover {
  cursor: pointer;
  opacity: .7;
}

.jdgm-gallery__thumbnail-wrapper {
  height: 100%;
  text-align: center;
  line-height: 100%;
  position: absolute;
  inset: 0;
}

.jdgm-gallery__thumbnail-wrapper:before {
  padding-top: calc(50% - 18px);
  font-size: 28px;
  line-height: 1;
  inset: 4px;
}

.jdgm-gallery__thumbnail-wrapper:before, .jdgm-gallery__thumbnail-wrapper[data-media-type="video"]:after {
  color: #fff;
  background-color: #000000b3;
  font-family: JudgemeIcons;
  position: absolute;
}

.jdgm-gallery__thumbnail-wrapper[data-media-type="video"]:after {
  content: "";
  border-top-right-radius: 4px;
  padding: 0 4px;
  font-size: 12px;
  bottom: 4px;
  left: 4px;
}

.jdgm-gallery__thumbnail-wrapper.jdgm--loading {
  visibility: hidden;
}

.jdgm-gallery__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 4px;
}

.jdgm-gallery-data {
  display: none;
}

.jdgm-review-widget--small .jdgm-gallery, .jdgm-review-widget--medium .jdgm-gallery, .jdgm-revs-tab .jdgm-gallery {
  width: calc(100% + 8px);
}

.jdgm-review-widget--medium .jdgm-gallery .jdgm-gallery__thumbnail-link {
  width: 16.6667%;
}

.jdgm-review-widget--medium .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
  display: inline-block;
}

.jdgm-review-widget--medium .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
  content: none;
}

.jdgm-review-widget--medium .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(6) .jdgm-gallery__thumbnail-wrapper:before {
  content: "";
}

.jdgm-review-widget--medium .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+7) {
  display: none;
}

.jdgm-review-widget--small .jdgm-gallery .jdgm-gallery__thumbnail-link {
  width: 25%;
}

.jdgm-review-widget--small .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
  display: inline-block;
}

.jdgm-review-widget--small .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
  content: none;
}

.jdgm-review-widget--small .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(4) .jdgm-gallery__thumbnail-wrapper:before {
  content: "";
}

.jdgm-review-widget--small .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+5) {
  display: none;
}

@media only screen and (min-width: 992px) {
  .jdgm-revs-tab .jdgm-gallery {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .jdgm-revs-tab .jdgm-gallery .jdgm-gallery__thumbnail-link {
    width: 12.5%;
  }

  .jdgm-revs-tab .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
    display: inline-block;
  }

  .jdgm-revs-tab .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
    content: none;
  }

  .jdgm-revs-tab .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(8) .jdgm-gallery__thumbnail-wrapper:before {
    content: "";
  }

  .jdgm-revs-tab .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+9) {
    display: none;
  }
}

.jdgm-gallery-popup .jm-mfp-container {
  padding: 0;
}

.jdgm-gallery-popup .jm-mfp-container .jm-mfp-arrow:hover {
  opacity: 1;
}

.jdgm-gallery-popup .jm-mfp-main {
  width: calc(100% - 40px);
  text-align: left;
  flex-direction: column;
  margin: 20px;
  padding-bottom: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.jdgm-gallery-popup .jm-mfp-loading, .jdgm-gallery-popup .jm-mfp-carousel-wrapper {
  background-color: #000;
}

.jdgm-gallery-popup .jm-mfp-loading, .jdgm-gallery-popup .jm-mfp-content-wrapper, .jdgm-gallery-popup .jm-mfp-figure > figure {
  height: 260px;
}

.jdgm-gallery-popup .jm-mfp-video-wrapper {
  height: 100%;
}

.jdgm-gallery-popup .jm-mfp-container > .jm-mfp-content {
  display: none;
}

.jdgm-gallery-popup .jm-mfp-content {
  width: 100%;
  height: 100%;
  z-index: auto;
}

.jdgm-gallery-popup .jm-mfp-close {
  z-index: 1045;
  text-align: center;
  cursor: pointer;
  color: #eee;
  font-size: 32px;
}

.jdgm-gallery-popup .jm-mfp-figure:after {
  content: none;
}

.jdgm-gallery-popup .jm-mfp-figure .jm-mfp-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  padding: 0;
}

.jdgm-gallery-popup .jm-mfp-figure .jm-mfp-iframe-wrapper {
  height: 100%;
}

.jdgm-gallery-popup .jm-mfp-figure .jm-mfp-iframe {
  width: 100%;
  height: 100%;
}

.jdgm-gallery-popup .jm-mfp-review-wrapper {
  background-color: #fff;
}

.jdgm-gallery-popup .jm-mfp-carousel {
  padding: 8px;
}

.jdgm-gallery-popup .jdgm-gallery {
  width: 100%;
  white-space: nowrap;
  margin: 0;
  overflow-x: auto;
}

.jdgm-gallery-popup .jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
  display: inline-block;
}

.jdgm-gallery-popup .jdgm-gallery .jdgm-gallery__thumbnail-wrapper:nth-child(n):before {
  content: none;
}

.jdgm-gallery-popup .jdgm-gallery__thumbnail {
  border: 2px solid #0000;
  padding: 2px;
}

.jdgm-gallery-popup .jdgm-gallery__thumbail-wrapper {
  padding: 2px;
}

.jdgm-gallery-popup .jdgm-gallery__thumbnail-link {
  float: none;
  opacity: .7;
}

.jdgm-gallery-popup .jdgm-gallery__thumbnail-link:hover, .jdgm-gallery-popup .jdgm-gallery__thumbnail-link--current {
  opacity: 1;
}

.jdgm-gallery-popup .jdgm-gallery__thumbnail-link--current .jdgm-gallery__thumbnail {
  background-color: #fff;
}

.jdgm-gallery-popup .jdgm-gallery__thumbnail-link:hover, .jdgm-gallery-popup .jdgm-gallery__thumbnail-link--current {
  opacity: 1;
}

@media only screen and (min-width: 992px) {
  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery {
    width: 100%;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link {
    width: 80px;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
    display: inline-block;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
    content: none;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(6) .jdgm-gallery__thumbnail-wrapper:before {
    content: "";
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n+7) {
    display: none;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link {
    width: 80px;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) {
    display: inline-block;
  }

  .jdgm-gallery-popup .jdgm-gallery.jdgm-gallery .jdgm-gallery__thumbnail-link:nth-child(n) .jdgm-gallery__thumbnail-wrapper:before {
    content: none;
  }
}

.jdgm-gallery-popup .jdgm-rev {
  margin: 0;
  padding: 20px;
}

.jdgm-gallery-popup .jdgm-rev.jdgm-divider-top {
  border-top: none;
}

.jdgm-gallery-popup .jdgm-rev .jdgm-rev__header {
  margin-bottom: 10px;
  padding-right: 20px;
}

.jdgm-gallery-popup .jdgm-rev__title {
  margin-bottom: 10px;
  font-size: 100%;
  font-weight: bold;
}

.jdgm-gallery-popup .jdgm-rev__body, .jdgm-gallery-popup .jdgm-rev-attachments {
  overflow: auto;
}

.jdgm-gallery-popup .jdgm-rev__body p:first-child {
  margin-top: 0;
}

.jdgm-gallery-popup .jdgm-rev-attachments {
  border-top: 1px solid #eee;
  margin-top: 20px;
}

.jdgm-gallery-popup .jdgm-rev-attachment {
  margin-top: 20px;
}

.jdgm-gallery-popup .jdgm-rev-attachment:after {
  content: "";
  clear: both;
  display: block;
}

.jdgm-gallery-popup .jdgm-rev-attachment__cover {
  float: left;
}

.jdgm-gallery-popup .jdgm-rev-attachment__thumbnail {
  height: 96px;
  width: 96px;
  object-fit: cover;
  background-color: #eaeaea;
}

.jdgm-gallery-popup .jdgm-rev-attachment__content {
  padding-left: 116px;
}

.jdgm-gallery-popup .jdgm-rev-attachment__review {
  font-size: 90%;
}

.jdgm-gallery-popup .jdgm-rev-attachment__price {
  padding: 5px 0 10px;
  font-size: 120%;
  font-weight: bold;
}

.jdgm-gallery-popup .jdgm-rev-attachment__btn {
  width: 100%;
  max-width: 200px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 10px 20px;
  display: block;
}

@media only screen and (min-width: 768px) {
  .jdgm-gallery-popup .jm-mfp-content-wrapper {
    height: calc(100% - 112px);
    display: block;
  }

  .jdgm-gallery-popup .jm-mfp-carousel {
    padding: 16px;
  }

  .jdgm-gallery-popup .jm-mfp-loading, .jdgm-gallery-popup .jm-mfp-figure, .jdgm-gallery-popup .jm-mfp-figure > figure {
    height: 100%;
  }

  .jdgm-gallery-popup .jm-mfp-close {
    color: #333;
  }

  .jdgm-gallery-popup .jdgm-rev {
    height: 100%;
  }

  .jdgm-gallery-popup .jdgm-rev__content {
    height: calc(100% - 20px);
  }

  .jdgm-gallery-popup .jdgm-rev__content.jdgm-rev__content--has-title {
    height: calc(100% - 80px);
  }

  .jdgm-gallery-popup .jdgm-rev__content.jdgm-rev__content--has-attachments {
    height: calc(100% - 90px);
  }

  .jdgm-gallery-popup .jdgm-rev__content.jdgm-rev__content--has-attachments .jdgm-rev__body {
    height: 40%;
  }

  .jdgm-gallery-popup .jdgm-rev__content.jdgm-rev__content--has-attachments .jdgm-rev-attachments {
    height: calc(60% - 40px);
    padding-bottom: 20px;
  }

  .jdgm-gallery-popup .jdgm-rev__body {
    max-height: calc(100% - 60px);
  }

  .jdgm-gallery-popup .jdgm-rev.jdgm-rev, .jdgm-gallery-popup .jdgm-rev__content {
    flex-direction: column;
    display: flex;
  }

  .jdgm-gallery-popup .jdgm-rev__content {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 992px) {
  .jdgm-gallery-popup .jm-mfp-main {
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    background-color: #fff;
    flex-direction: row;
    margin: 40px;
    padding-bottom: 0;
  }

  .jdgm-gallery-popup .jm-mfp-carousel-wrapper {
    width: calc(100% - 408px);
  }
}

.jdgm-gallery-popup .jdgm-rev {
  float: none;
  width: 100%;
  background-color: #fff;
  border: none;
  display: block;
}

.jdgm-gallery-popup .jdgm-rev__header {
  padding: 0;
}

.jdgm-gallery-popup .jdgm-rev__icon {
  float: left;
  width: 3.2em;
  height: 3.2em;
  text-align: center;
  color: #333;
  background-color: #e9e9e9;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 10px;
  line-height: 3.2em;
  display: block;
  position: relative;
}

.jdgm-gallery-popup .jdgm-rev__icon:after {
  content: none;
}

.jdgm-gallery-popup .jdgm-rev__rating, .jdgm-gallery-popup .jdgm-rev__timestamp, .jdgm-gallery-popup .jdgm-rev__buyer-badge-wrapper, .jdgm-gallery-popup .jdgm-rev__author-wrapper {
  width: auto;
  white-space: normal;
  font-size: inherit;
  line-height: 1.4;
  display: inline-block;
  position: relative;
}

.jdgm-gallery-popup .jdgm-rev__author-wrapper {
  font-weight: bold;
}

.jdgm-gallery-popup .jdgm-rev__timestamp {
  opacity: .6;
  margin-left: 4px;
  margin-right: 0;
}

.jdgm-gallery-popup .jdgm-rev__prod-info-wrapper {
  border-top: none;
  padding: 0;
  font-size: 100%;
}

.jdgm-gallery-popup .jdgm-rev__prod-link-prefix {
  display: inline-block;
}

.jdgm-gallery-popup .jdgm-rev__author-wrapper {
  float: none;
  margin-top: 2px;
  display: block;
}

.jdgm-gallery-popup .jdgm-rev__location {
  margin: 0;
  display: inline-block;
}

.jdgm-gallery-popup .jdgm-rev__content {
  width: 100%;
  margin: 0;
  padding: 0;
}

.jdgm-gallery-popup .jdgm-rev__custom-form {
  margin-bottom: 16px;
}

.jdgm-widget.jdgm-medals-wrapper {
  width: 100%;
  color: #399;
  justify-content: center;
  align-items: center;
  display: flex;
}

.jdgm-medals-wrapper {
  text-align: center;
  padding: 16px;
}

.jdgm-medals, .jdgm-medals__container, .jdgm-medal-wrapper, .jdgm-medal {
  display: inline-block;
}

.jdgm-medals-wrapper .jdgm-medals__container {
  padding: 16px 0;
}

.jdgm-medals-wrapper .jdgm-medal-wrapper {
  margin: 0 8px;
}

.jdgm-medal {
  width: 64px;
  height: 64px;
  position: relative;
}

.jdgm-medal.jdgm--loading:after {
  width: 100%;
  height: 100%;
  content: "";
  border: 2px solid #eee;
  border-left-color: #333;
  border-radius: 50%;
  animation: .8s linear infinite jdgm-spin;
  display: block;
  position: absolute;
  top: 0;
}

.jdgm-medal.jdgm--loading .jdgm-medal__value {
  display: none;
}

.jdgm-medal[data-tier="bronze"] .jdgm-medal__value {
  color: #a36710;
}

.jdgm-medal[data-tier="silver"] .jdgm-medal__value {
  color: #666;
}

.jdgm-medal[data-tier="gold"] .jdgm-medal__value {
  color: #d2920f;
}

.jdgm-medal[data-tier="platinum"] .jdgm-medal__value {
  color: #488282;
}

.jdgm-medal[data-tier="diamond"] .jdgm-medal__value {
  color: #4c8eda;
}

.jdgm-medal__image, .jdgm-medal__image > img, .jdgm-medal__image > div, .jdgm-medal__image svg {
  width: 100%;
  height: 100%;
}

.jdgm-medal__value {
  width: 64px;
  text-align: center;
  font-family: Nunito Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 32px;
}

.jdgm-verified-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
}

.jdgm-verified-wrapper .jdgm-rating {
  margin-bottom: 4px;
}

.jdgm-rating__stars {
  margin-right: 4px;
}

.jdgm-rating__stars .jdgm-star {
  color: #399;
}

.jdgm-medals-wrapper:not(.jdgm-medals-wrapper--small) .jdgm-verified-wrapper:first-child {
  border-right: 1px solid #c1e6e6;
  margin-right: 16px;
  padding-right: 16px;
}

.jdgm-medals-wrapper:not(.jdgm-medals-wrapper--small) .jdgm-verified-wrapper:last-child {
  border-left: 1px solid #c1e6e6;
  margin-left: 16px;
  padding-left: 16px;
}

.jdgm-medals-wrapper--small {
  flex-direction: column;
}

.jdgm-medals-wrapper--small .jdgm-medals {
  max-width: 288px;
  overflow: auto;
}

.jdgm-medals-wrapper--small .jdgm-medal {
  width: 80px;
  height: 80px;
}

.jdgm-medals-wrapper--small .jdgm-medal__value {
  width: 80px;
  font-size: 19.2px;
  top: 38px;
}

.jdgm-widget.jdgm-medals-wrapper {
  color: #399;
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals-wrapper {
  border-top: 1px solid var(--jdgm-secondary-color);
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals {
  max-width: 100%;
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals .jdgm-medals__container {
  white-space: nowrap;
  display: block;
  overflow: auto;
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals .jdgm-medal, .jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals .jdgm-medal__mono svg {
  width: 80px;
  height: 80px;
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals .jdgm-medal__image {
  max-width: 100%;
}

.jdgm-widget:not(.jdgm-medals-wrapper) .jdgm-medals .jdgm-medal__value {
  width: 80px;
  font-size: 19.2px;
  top: 40px;
}

.jdgm-widget.jdgm-review-widget .jdgm-medals .jdgm-medal-wrapper {
  margin-left: 0;
  margin-right: 16px;
}

.jdgm-widget.jdgm-review-widget .jdgm-medals .jdgm-medal__value {
  width: 32px;
  letter-spacing: 0;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  top: auto;
  bottom: -18px;
  left: 24px;
}

.jdgm-ugc-media-wrapper {
  text-align: center;
  clear: both;
  margin: 20px auto;
}

.jdgm-ugc-media-wrapper:after {
  content: "";
  clear: both;
  display: block;
}

.jdgm-ugc-media-wrapper[data-cannot-fetch] {
  padding-bottom: 40px;
}

.jdgm-ugc-media-title {
  padding: 20px;
}

.jdgm-ugc-media-title h3 {
  margin-bottom: 5px;
}

.jdgm-ugc-media {
  padding: 10px;
}

.jdgm-ugc-media__thumbnail-link {
  float: left;
  width: 50%;
  position: relative;
}

.jdgm-ugc-media__thumbnail-link:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.jdgm-ugc-media__thumbnail-link:hover {
  cursor: pointer;
}

.jdgm-ugc-media__thumbnail-link:hover .jdgm-ugc-media__thumbnail-wrapper:before {
  content: "";
  background-color: #0009;
  position: absolute;
  inset: 10px;
}

.jdgm-ugc-media__thumbnail-link:hover .jdgm-ugc-media__actions {
  display: block;
}

.jdgm-ugc-media__thumbnail-link[data-media-type="video"]:after {
  color: #fff;
  content: "";
  background-color: #000c;
  border-radius: 16px;
  padding: 4px 26px 4px 30px;
  font-family: JudgemeIcons;
  font-size: 32px;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 40px);
}

.jdgm-ugc-media__thumbnail-wrapper {
  height: 100%;
  text-align: center;
  line-height: 100%;
  position: absolute;
  inset: 0;
}

.jdgm-ugc-media__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 10px;
}

.jdgm-ugc-media__primary-btn, .jdgm-ugc-media__secondary-btn, .jdgm-ugc-media__reviews-btn {
  white-space: nowrap;
  cursor: pointer;
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 90%;
  line-height: 1;
  display: inline-block;
}

.jdgm-ugc-media__primary-btn[disabled], .jdgm-ugc-media__secondary-btn[disabled], .jdgm-ugc-media__reviews-btn[disabled] {
  pointer-events: none;
  opacity: .6;
}

.jdgm-ugc-media__actions {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.jdgm-ugc-media__actions .jdgm-ugc-media__primary-btn {
  margin-bottom: 0;
  font-weight: bold;
}

.jdgm-ugc-media__icon-instagram {
  height: 32px;
  color: #fff;
  font-family: JudgemeIcons;
  font-size: 32px;
}

.jdgm-ugc-media__icon-instagram:empty {
  display: block;
}

.jdgm-ugc-media__icon-instagram:before {
  content: "";
  height: 32px;
  vertical-align: top;
  line-height: 32px;
}

@media only screen and (min-width: 768px) {
  .jdgm-ugc-media__thumbnail-link {
    width: 33.3333%;
  }
}

@media only screen and (min-width: 992px) {
  .jdgm-ugc-media-wrapper[data-cannot-fetch] {
    padding-bottom: 60px;
  }

  .jdgm-ugc-media__primary-btn, .jdgm-ugc-media__secondary-btn, .jdgm-ugc-media__reviews-btn {
    padding: 10px 40px;
    font-size: 100%;
  }
}

/*# sourceMappingURL=shopify_v2.5cb9c138.css.map */
